import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/select-device',
    name: 'select-device',
    component: () => import(/* webpackChunkName: "about" */ '../views/SelectDeviceView.vue')
  },
  {
    path: '/device-details/:id',
    name: 'device-details',
    params: true,
    component: () => import(/* webpackChunkName: "device-details" */ '../views/DeviceDetailsView.vue')
  },
  {
    path: '/enter-bvn',
    name: 'enter-bvn',
    component: () => import(/* webpackChunkName: "enter-bvn" */ '../views/EnterBvnView.vue')
  },
  {
    path: '/personal-details',
    name: 'personal-details',
    component: () => import(/* webpackChunkName: "enter-bvn" */ '../views/PersonalDetailsView.vue')
  },
  {
    path: '/loan-summary',
    name: 'loan-summary',
    component: () => import(/* webpackChunkName: "enter-bvn" */ '../views/LoanSummaryView.vue')
  },
  {
    path: '/select-pickup-location',
    name: 'select-pickup-location',
    component: () => import(/* webpackChunkName: "enter-bvn" */ '../views/SelectPickUpView.vue')
  },
  {
    path: '/application/:reference/complete',
    name: 'complete-application',
    params: true,
    component: () => import(/* webpackChunkName: "complete-application" */ '../views/CompleteApplicationView.vue')
  }
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
