
import { createStore } from 'vuex'

export default createStore({
  state: {
    currentStep: 1,
    devices: [],
    selectedLoanType: null,
    selectedState: null,
    form: {
      loan_type: null,
      pick_up_location: null,
      product_id: null,
      amount: 0,
      address: null,
      city: null,
      account_number: null,
      bvn: null,
      bank_code: null,
      state: null,
      reffered_by: null,
      tenor: null,
      remita_bank_code: null,
      service_completion_date: null,
      first_name: null,
      last_name: null,
      email: null,
      phone_number: null,
      gender: null,
      referral_ref_id: null
    }
  },
  getters: {
  },
  mutations: {
    UPDATE_STEP (state, payload) {
      state.currentStep += payload
    },
    SET_DEVICES (state, payload) {
      state.devices = payload
    },
    UPDATE_FORM (state, payload) {
      state.form = payload
    },
    SET_LOAN_TYPE (state, payload) {
      state.selectedLoanType = payload
    },
    SET_STATE (state, payload) {
      state.selectedState = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
