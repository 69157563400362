<template>
  <div class="home">
    <div class="landing-page">
        <!-- Navbar -->
        <nav class="navbar navbar-expand-lg navbar-light">
            <div class="container-fluid">
                <a class="navbar-brand" href="#">
                    <img
                        src="/assets/images/Corperflex Logo.svg"
                        alt="Logo"
                        class="logo"
                    />
                </a>
                <router-link class="btn12" to="/select-device" >Apply Now</router-link>
            </div>
        </nav>
        <div class="image-container col-lg-5 col-md-6 col-sm-8 col-10">
            <img src="/assets/images/Vector 1 (Stroke).svg" alt="Background Image"/>
        </div>
        <div class="container">
            <div class="circle-container col-lg-5 col-md-6 col-sm-8 col-10">
            <div class="circle"></div>
        </div>
    </div>

        <!-- Hero Section -->
        <div class="hero-section text-center my-5">
            <div class="container">
                <h1>
                    Own a <span>Samsung phone</span>, pay less <br />
                    than 50% of your allawee monthly!
                </h1>
                <p>Flex with Zero Upfront. Own now, Pay later</p>
                <router-link to="/select-device" class="btn13 btn-lg">Apply Now <img src="/assets/images//arrow-left-02.svg" alt="touch it" /></router-link>
            </div>
        </div>

        <!-- How It Works Section -->
        <section class="how-it-works" id='how-it-works'>
            <div class="container">
                <h2 class="text-center">How it <span>works?</span></h2>
                <div class="row my-5">
                    <div  v-for="step in stepsToApply" :key="step" class="col-sm-12 col-md-6 col-xl-6 mb-4">
                            <div class="card">

                                <div class="card-header">
                                    <h5 class="card-title">{{step.title}}</h5>
                                    <div class="icon">
                                        <p>{{step.p}}</p>
                                    </div>
                                </div>
                                <img
                                    :src="step.img"
                                    :alt="step.title"
                                    class="card-img-top"
                                />

                            </div>
                        </div>

                </div>
            </div>
        </section>

        <!--Who can apply section-->
        <section class="who-can-apply">
            <div class="blue-card">
                <div class="content">

                <div class="apply">
                    <h2>Who can apply?</h2>
                    <div class="container-icon">
                      <img src="/assets/images/Dawn.svg" alt="" />
                        <p> To be eligible, you must: <br/> Be a serving NYSC Corper <br/>with a Valid ID Card</p>
                    </div>
                    <div class="container-icon"><img src="/assets/images/Dawn.svg"
                    alt="" /> <p>Having a minimum of <br/>4 months remaining in your service year.</p></div>
                    <router-link to="select-device" class="btn btn-custom">
                      <div class="shift">Apply now <img src="/assets/images/arrow-left green.svg" alt="touch it" /></div>
                    </router-link>
                  </div>
                  <img src="/assets/images/Lady search.svg" alt="Apply Now Image" />
                </div>
              </div>
        </section>

        <!-- Footer -->
        <footer class="footer bg-dark text-white py-3 text-center">
            <div class="container">
                <a href="#">
                    <img
                        src="/assets/images/Corperflex Logo.svg"
                        alt="Footer Logo"
                        class="logo-footer"
                    />
                </a>
                <div class="mt-3">
                    <a href="#how-it-works" class="text-white me-3">How it Works</a>
                    <a href="#contact" class="text-white">Contact Us</a>
                </div>
            </div>
            <p class="mt-3">&copy; 2024 CorperFlex. All rights reserved.</p>
        </footer>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { RouterLink } from 'vue-router'
// import img2 from '/assets/breakdown-stepper-icon 1.svg';

export default {
  name: 'HomeView',
  components: {
    RouterLink
  },
  data () {
    return {
      stepsToApply: [
        { id: 1, title: 'Select a device', p: '1', img: '/assets/images/Frame sd.svg' },
        { id: 2, title: 'Enter necessary information', p: '2', img: '/assets/images/Frame eni.svg' },
        { id: 3, title: 'Get approval', p: '3', img: '/assets/images/Frame ga.svg' },
        { id: 4, title: 'Select pickup option', p: '4', img: '/assets/images/Frame spp.svg' }
      ]
    }
  }
}
</script>

<style scoped>
   @import '../styles/LandingPage.css';
</style>
